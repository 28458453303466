import React from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { styleReset } from 'react95';
// import original from 'react95/dist/themes/original';
import Main from 'page/main';
import './fonts.css';

const original = {
  name: 'original',
  anchor: '#1034a6',
  anchorVisited: '#440381',
  borderDark: '#0B163F', // good
  borderDarkest: '#070F28', // good
  borderLight: '#2D467F', // good
  borderLightest: '#2A5ABA', // good
  canvas: '#ffffff',
  canvasText: '#0a0a0a',
  canvasTextDisabled: '#848584',
  canvasTextDisabledShadow: '#fefefe',
  canvasTextInvert: '#fefefe',
  checkmark: '#0a0a0a',
  checkmarkDisabled: '#848584',
  desktopBackground: '#008080',
  flatDark: '#9e9e9e',
  flatLight: '#d8d8d8',
  focusSecondary: '#fefe03',
  headerBackground: '#060084',
  headerNotActiveBackground: '#7f787f',
  headerNotActiveText: '#c6c6c6',
  headerText: '#fefefe',
  hoverBackground: '#060084',
  material: '#252E4F', // good
  materialDark: '#9a9e9c',
  materialText: '#F8F9FF', // good1
  materialTextDisabled: '#848584',
  materialTextDisabledShadow: '#fefefe',
  materialTextInvert: '#fefefe',
  progress: '#060084',
  tooltip: '#fefbcc',
};

const GlobalStyles = createGlobalStyle`
  ${styleReset}
  body {
    font-family: 'ms_sans_serif',sans-serif;
    color: #F8F9FF;
    letter-spacing: 1.5px;
    box-sizing: border-box;
  }
`;

const App = () => (
  <div>
    <GlobalStyles />
    <ThemeProvider theme={original}>
      <Main />
    </ThemeProvider>
  </div>
);

export default App;
