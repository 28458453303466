export const shortenAddressTo11Chars = (address: string) => `
    ${address.slice(0, 4)}...${address.slice(address.length - 4)}
`;
export const toHex = (num:number) => {
  const val = Number(num);
  return `0x${val.toString(16)}`;
};

export const truncateAddress = (address:string) => {
  if (!address) return 'No Account';
  const match = address.match(
    /^(0x[a-zA-Z0-9]{4})[a-zA-Z0-9]+([a-zA-Z0-9]{4})$/,
  );
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};
