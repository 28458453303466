import Styled, { css } from 'styled-components';
import { tablet } from '../../utils';

export const Wrapper = Styled.div`
    display: grid;
    box-sizing: border-box;
    min-height: 100vh;
    height: auto;
    grid-template-rows: 72px 1fr auto;
    background: #1F232F;
    //min-height: calc(100vh - 133px);
    ${tablet(css`
      height: auto;
    `)};
`;
export const BaseComponentWrapper = Styled.div`
  padding: 0 24px;
  ${tablet(css`
    padding: 15px;
  `)};
`;
