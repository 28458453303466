export const text = {
  about: {
    name: 'About.exe',
    content: [
      {
        id: 0,
        a: '',
        q: '',
        list: [],
        note: 'The UniWhalooor loyalty NFT adds another dimension to the UniWhales DAO and provides a composable building block for on-chain reputations that can be utilized by other Web3 protocols.',
      },
      {
        id: 1,
        a: '',
        q: '',
        list: [],
        note: 'Not your average JPEG, they are linked to upgradeable on-chain and off-chain metadata.',
      },
      {
        id: 2,
        a: '',
        q: '',
        list: [],
        note: 'The NFTs were programmed by Passage Protocol and designed by artist Artusmichal.eth.',
      },
      {
        id: 3,
        a: '',
        q: '',
        list: [],
        note: 'UniWhales DAO is a cohesive, collaborative group of some of the biggest DeFi and NFT native builders + investors around.',
      },
      {
        id: 4,
        a: '',
        q: '',
        list: [],
        note: 'We`ve been together  since November 2020 and have one of the most loyal communities in all of Web3.',
      },
      {
        id: 5,
        a: '',
        q: '',
        list: [],
        note: 'Read more here:',
        link: 'https://medium.com/@uniwhalesio/introducing-the-uniwhalooor-loyalty-nft-75b03d6229b8',
      },
    ],
  },
  claim: {
    name: 'Claim.exe',
  },
  faqs: {
    name: 'FAQs.exe',
    content: [
      {
        id: 0,
        q: 'Q: What value do the Loyalty NFTs add to the UniWhales community?',
        a: 'A: Two reasons:',
        list: [
          {
            id: 0, text: 'Strengthen the UniWhales DAO by better scoring member contributions',
          },
          {
            id: 1, text: 'Provide a composable building block for on-chain reputations that can be utilized by other Web3 protocols.',
          },
        ],
        note: 'For a more in-depth explanation, please read the about page',
      },
      {
        id: 1,
        q: 'Q: Who is eligible to mint?',
        a: 'A: Any wallet with at least 5000 UWL.',
        list: [],
        note: '',
      },
      {
        id: 2,
        q: 'Q: What is the mint price?',
        a: 'A: Free plus gas fees',
        list: [],
        note: '',
      },
      {
        id: 3,
        q: 'Q: How long does this mint last?',
        a: 'A: The mint is always open so that new DAO members are able to get a loyalty NFT.  But only one NFT can ever be minted per address.',
        list: [],
        note: '',
      },
      {
        id: 4,
        q: 'Q: Is the NFT Transferrable?',
        a: 'A: Think of it as a hybrid between a soulbound token and a typical NFT. Technically, it can be transferred.',
        list: [],
        note: 'But upon transfer, the loyalty traits and metadata reset and all accumulated metadata will be lost.\n',
      },
      {
        id: 5,
        q: 'Q: What are the primary traits of the NFT image?',
        a: 'A: The NFT will dynamically update three traits in the art work:',
        list: [
          {
            id: 0, text: 'Membership tier - pro, whale, alumni',
          },
          {
            id: 1, text: 'Streak - consecutive days member wallet has held at least 5000 UWL',
          },
          {
            id: 2, text: 'Duration - total number of days wallet has held at least 5000 UWL',
          },
        ],
        note: '',
      },
      {
        id: 6,
        q: 'Q: What other data points can the NFT track?',
        a: 'A: The loyalty NFT itself can be upgraded by on-chain and off-chain metadata, literally reflecting anything in the imagination! Check the about page for more details.',
        list: [],
        note: '',
      },
    ],
  },
  undefined: {
    name: 'undefined.exe',
  },
};

export const contractABI = [{ inputs: [{ internalType: 'address', name: 'uniNFTContractAddress', type: 'address' }, { internalType: 'address', name: 'uniTokenContractAddress', type: 'address' }, { internalType: 'uint256', name: 'uniTokenMinimum', type: 'uint256' }], stateMutability: 'nonpayable', type: 'constructor' }, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32',
  }, {
    indexed: true, internalType: 'bytes32', name: 'previousAdminRole', type: 'bytes32',
  }, {
    indexed: true, internalType: 'bytes32', name: 'newAdminRole', type: 'bytes32',
  }],
  name: 'RoleAdminChanged',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32',
  }, {
    indexed: true, internalType: 'address', name: 'account', type: 'address',
  }, {
    indexed: true, internalType: 'address', name: 'sender', type: 'address',
  }],
  name: 'RoleGranted',
  type: 'event',
}, {
  anonymous: false,
  inputs: [{
    indexed: true, internalType: 'bytes32', name: 'role', type: 'bytes32',
  }, {
    indexed: true, internalType: 'address', name: 'account', type: 'address',
  }, {
    indexed: true, internalType: 'address', name: 'sender', type: 'address',
  }],
  name: 'RoleRevoked',
  type: 'event',
}, {
  inputs: [], name: 'DEFAULT_ADMIN_ROLE', outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'claim', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }], name: 'getRoleAdmin', outputs: [{ internalType: 'bytes32', name: '', type: 'bytes32' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }, { internalType: 'address', name: 'account', type: 'address' }], name: 'grantRole', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }, { internalType: 'address', name: 'account', type: 'address' }], name: 'hasRole', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'mintEnabled', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'address', name: '', type: 'address' }], name: 'minted', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }, { internalType: 'address', name: 'account', type: 'address' }], name: 'renounceRole', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'bytes32', name: 'role', type: 'bytes32' }, { internalType: 'address', name: 'account', type: 'address' }], name: 'revokeRole', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'bool', name: 'enabled', type: 'bool' }], name: 'setEnableMint', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'uint256', name: 'newTokenMinimum', type: 'uint256' }], name: 'setTokenMinimum', outputs: [], stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ internalType: 'bytes4', name: 'interfaceId', type: 'bytes4' }], name: 'supportsInterface', outputs: [{ internalType: 'bool', name: '', type: 'bool' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'tokenMinimum', outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'uniNFT', outputs: [{ internalType: 'contract IPassport', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
}, {
  inputs: [], name: 'uniToken', outputs: [{ internalType: 'contract IERC20', name: '', type: 'address' }], stateMutability: 'view', type: 'function',
}];

export const CONTRACT_UWL = [{
  constant: true, inputs: [], name: 'name', outputs: [{ name: '', type: 'string' }], payable: false, stateMutability: 'view', type: 'function',
}, {
  constant: false, inputs: [{ name: 'spender', type: 'address' }, { name: 'tokens', type: 'uint256' }], name: 'approve', outputs: [{ name: 'success', type: 'bool' }], payable: false, stateMutability: 'nonpayable', type: 'function',
}, {
  constant: true, inputs: [], name: 'startDate', outputs: [{ name: '', type: 'uint256' }], payable: false, stateMutability: 'view', type: 'function',
}, {
  constant: true, inputs: [], name: 'totalSupply', outputs: [{ name: '', type: 'uint256' }], payable: false, stateMutability: 'view', type: 'function',
}, {
  constant: false, inputs: [], name: 'limitTrade', outputs: [], payable: false, stateMutability: 'nonpayable', type: 'function',
}, {
  constant: false, inputs: [], name: 'RemoveLimitTrade', outputs: [], payable: false, stateMutability: 'nonpayable', type: 'function',
}, {
  constant: false, inputs: [{ name: 'from', type: 'address' }, { name: 'to', type: 'address' }, { name: 'tokens', type: 'uint256' }], name: 'transferFrom', outputs: [{ name: 'success', type: 'bool' }], payable: false, stateMutability: 'nonpayable', type: 'function',
}, {
  constant: true, inputs: [{ name: 'from', type: 'address' }], name: 'getVaultReleaseTimeFrom', outputs: [{ name: 'releaseTime', type: 'uint256' }], payable: false, stateMutability: 'view', type: 'function',
}, {
  constant: true, inputs: [], name: 'decimals', outputs: [{ name: '', type: 'uint8' }], payable: false, stateMutability: 'view', type: 'function',
}, {
  constant: true, inputs: [], name: '_totalSupply', outputs: [{ name: '', type: 'uint256' }], payable: false, stateMutability: 'view', type: 'function',
}, {
  constant: true, inputs: [], name: 'limitTradeByOwner', outputs: [{ name: '', type: 'bool' }], payable: false, stateMutability: 'view', type: 'function',
}, {
  constant: false, inputs: [{ name: 'to', type: 'address' }], name: 'releateTokenTo', outputs: [], payable: false, stateMutability: 'nonpayable', type: 'function',
}, {
  constant: true, inputs: [{ name: 'tokenOwner', type: 'address' }], name: 'balanceOf', outputs: [{ name: 'balance', type: 'uint256' }], payable: false, stateMutability: 'view', type: 'function',
}, {
  constant: false, inputs: [{ name: '_isLocked', type: 'bool' }], name: 'setIsLocked', outputs: [], payable: false, stateMutability: 'nonpayable', type: 'function',
}, {
  constant: true, inputs: [], name: 'owner', outputs: [{ name: '', type: 'address' }], payable: false, stateMutability: 'view', type: 'function',
}, {
  constant: true, inputs: [], name: 'symbol', outputs: [{ name: '', type: 'string' }], payable: false, stateMutability: 'view', type: 'function',
}, {
  constant: true, inputs: [], name: 'isLocked', outputs: [{ name: '', type: 'bool' }], payable: false, stateMutability: 'view', type: 'function',
}, {
  constant: false, inputs: [{ name: 'to', type: 'address' }, { name: 'tokens', type: 'uint256' }], name: 'transfer', outputs: [{ name: 'success', type: 'bool' }], payable: false, stateMutability: 'nonpayable', type: 'function',
}, {
  constant: true, inputs: [], name: 'getVaultList', outputs: [{ name: 'list', type: 'address[]' }], payable: false, stateMutability: 'view', type: 'function',
}, {
  constant: true, inputs: [], name: 'newOwner', outputs: [{ name: '', type: 'address' }], payable: false, stateMutability: 'view', type: 'function',
}, {
  constant: true, inputs: [], name: 'getVaultAmount', outputs: [{ name: 'amount', type: 'uint256' }], payable: false, stateMutability: 'view', type: 'function',
}, {
  constant: true, inputs: [{ name: 'tokenOwner', type: 'address' }, { name: 'spender', type: 'address' }], name: 'allowance', outputs: [{ name: 'remaining', type: 'uint256' }], payable: false, stateMutability: 'view', type: 'function',
}, {
  constant: true, inputs: [{ name: 'from', type: 'address' }], name: 'getVaultAmountFrom', outputs: [{ name: 'amount', type: 'uint256' }], payable: false, stateMutability: 'view', type: 'function',
}, {
  constant: false, inputs: [], name: 'releaseToken', outputs: [], payable: false, stateMutability: 'nonpayable', type: 'function',
}, {
  constant: false, inputs: [{ name: 'to', type: 'address' }, { name: 'amount', type: 'uint256' }, { name: 'releastTime', type: 'uint256' }], name: 'tokenToVault', outputs: [], payable: false, stateMutability: 'nonpayable', type: 'function',
}, {
  constant: true, inputs: [], name: 'getVaultReleaseTime', outputs: [{ name: 'releaseTime', type: 'uint256' }], payable: false, stateMutability: 'view', type: 'function',
}, {
  constant: false, inputs: [{ name: '_newOwner', type: 'address' }], name: 'transferOwnership', outputs: [], payable: false, stateMutability: 'nonpayable', type: 'function',
}, {
  inputs: [{ name: 'multisig', type: 'address' }, { name: 'tokens', type: 'uint256' }], payable: false, stateMutability: 'nonpayable', type: 'constructor',
}, { payable: true, stateMutability: 'payable', type: 'fallback' }, {
  anonymous: false, inputs: [{ indexed: true, name: '_from', type: 'address' }, { indexed: true, name: '_to', type: 'address' }], name: 'OwnershipTransferred', type: 'event',
}, {
  anonymous: false, inputs: [{ indexed: true, name: 'from', type: 'address' }, { indexed: true, name: 'to', type: 'address' }, { indexed: false, name: 'tokens', type: 'uint256' }], name: 'Transfer', type: 'event',
}, {
  anonymous: false, inputs: [{ indexed: true, name: 'tokenOwner', type: 'address' }, { indexed: true, name: 'spender', type: 'address' }, { indexed: false, name: 'tokens', type: 'uint256' }], name: 'Approval', type: 'event',
}];
