import React from 'react';
import { Header, Content, Footer } from 'components/index';
import { Wrapper } from './main.style';
import { UseWeb3 } from '../../hooks/useWeb3';
import { truncateAddress } from '../../utils';

const Main = () => {
  const {
    network, account, disconnectWallet, connectWallet, isChainWrong, switchNetwork,
    isLoading, mint, error, transactionHash, nftID, balance,
  } = UseWeb3();
  return (
    <Wrapper>
      <Header
        network={network}
        account={truncateAddress(account || '')}
        disconnectWallet={disconnectWallet}
        connectWallet={connectWallet}
        isChainWrong={isChainWrong}
        switchNetwork={switchNetwork}
      />
      <Content
        account={account || null}
        balance={balance}
        isLoading={isLoading}
        mint={mint}
        error={error}
        transactionHash={transactionHash}
        nftID={nftID}
      />
      <Footer />
    </Wrapper>
  );
};
export default Main;
