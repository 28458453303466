import Styled, { css } from 'styled-components';
import {
  Window, Button, WindowHeader, WindowContent,
} from 'react95';
import { fixedHeight, tablet } from '../../utils';
import { BaseComponentWrapper } from '../../page/main/main.style';

const BasicFlex = css`
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const LoadingWrapper = Styled.div`
  width: 100%;
  ${BasicFlex};
  gap: 10px;
`;
export const TextElement = Styled.p<{ size?: string, textDecoration?: string, fontWeight?: string, color?:string }>`
  font-size: ${({ size }) => (`${size}px` || '16px')};
  color: ${({ color }) => color || 'white'};
  font-weight: ${({ fontWeight }) => (fontWeight || 'normal')};
  text-decoration: ${({ textDecoration }) => (textDecoration || 'none')};
`;
export const TextElementTitle = Styled(TextElement)`
  ${tablet(css`
    font-size: 18px;
  `)};
`;
export const TextElementSubtitle = Styled(TextElement)`
  ${tablet(css`
    font-size: 16px;
  `)};
`;
export const Wrapper = Styled(BaseComponentWrapper)`
  ${BasicFlex};
  gap: 40px;
  max-width: 680px;
  margin: 10px auto;
`;
export const FAQSWrapper = Styled.div`
  text-align: left;
  display: flex;
  margin-bottom: 15px;
  flex-direction: column;
  gap: 10px;
  li {
    letter-spacing: 1.5px;
    margin: 0 0 10px 35px;
    list-style-type: square;
  }
`;
export const AboutWrapper = Styled(FAQSWrapper)`
  margin-bottom: 0;
  gap: 5px;
`;
export const Header = Styled.div<{ activeTab?: number | null, fullWith: boolean }>`
  ${BasicFlex};
  text-align: center;
  display: ${(props:{ fullWith: boolean }) => (
    (!props.fullWith) ? 'flex' : 'none')};
  
  ${fixedHeight(css`
    display: ${(props:{ activeTab: string | null, fullWith: boolean }) => (
    (props.activeTab === null && !props.fullWith) ? 'flex' : 'none')};
  `)};
  
`;
export const Main = Styled.div`
  ${BasicFlex};
  justify-content: space-between;
  flex-direction: row;
  gap: 64px;
`;
export const MainMobile = Styled(Main)<{ activeTab?: number | null }>`
  display: none;
  flex-direction: column;
  ${tablet(css`
    display: flex;
  `)};
`;
export const MainDesktop = Styled(Main)<{ activeTab?: number | null }>`
  display: flex;
  ${tablet(css`
    display: none;
  `)};
`;
export const Bar = Styled.div`
  display: flex;
  flex-direction: row;
  gap: 2px;
`;
export const Img = Styled.img<{ maxWidth?: string }>`
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : '100%')}
`;
export const TextWrapper = Styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
`;
export const EthereumWrapper = Styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
export const MobileNavigation = Styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  min-width: 310px;
`;
export const CustomWrapper = Styled.div<{ height?: string }>`
  height: ${(props) => (props.height ? props.height : 'auto')};
  ${BasicFlex};
  gap: 10px;
`;
// custom window 95
export const CustomWindow = Styled(Window)`
  width: ${(props) => (props.fullWith ? 'calc(100vw - 48px)' : '354px')};
  height: ${(props) => (props.fullWith && !props.hideToBottom
    ? 'calc(100vh - 200px)' : 'auto')};
`;
export const CustomWindowHeader = Styled(WindowHeader)`
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(90deg, #4A00E0 0%, #2D75E2 94.73%);
`;
export const BarButton = Styled(Button)`
  width: 22px!important;
  height: 22px!important;
  :nth-child(1) {
    padding-top: 10px;
  }
`;
export const CustomButton = Styled(Button)`
  letter-spacing: 1.5px;
`;
export const NavigationButton = Styled(Button)<{
  fullWith: boolean
}>`
  width: 100px;
  height: 100px;
  display: ${(props) => (props.fullWith ? 'none' : 'flex')};
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

export const CustomWindowContent = Styled(WindowContent)<{
  hideToBottom: boolean,
  activeTab: number,
  fullWith: boolean
}>`
  display: ${(props) => (props.hideToBottom ? 'none' : 'flex')};
  padding: 30px;
  flex-direction: column;
  height: ${(props) => {
    if (props.fullWith) {
      return 'calc(100vh - 320px)';
    }
    if (!props.fullWith) {
      return '495px';
    }
    return 'auto';
  }};
  overflow: auto;
  gap: 30px;
  text-align: center;
  ${fixedHeight(css`
    padding: 19px;
  `)};
`;
