import Styled, { css } from 'styled-components';
import { Button, List } from 'react95';
import { BaseComponentWrapper } from '../../page/main/main.style';
import { tablet } from '../../utils/media';

export const Wrapper = Styled(BaseComponentWrapper)`
  display: grid;
  grid-template-columns: 133px  1fr;
  background: #252E4F;
`;
export const HeaderSection = Styled.div`
  position: relative;
  align-items: center;
  justify-content: end;
  display: flex;
  gap: 24px;
`;
export const CustomButton = Styled(Button)<{ width: string }>`
  height: 46px;
  width: ${(props) => (props.width ? props.width : '133px')};
  font-size: 16px;
  letter-spacing: 1.5px;
`;
export const Img = Styled.img`
  margin-right : 7px;
`;
export const HeaderList = Styled(List)`
  position: absolute;
  left: 0;
  top: 85%;
  width: auto;
  z-index: 100;
`;
export const NetworkSwitch = Styled.div<{ isChainWrong:boolean }>`
  display: flex;
  flex-direction: column;
  color: ${(props) => (props.isChainWrong ? 'tomato' : 'white')};
  ${tablet(css`
    display: none;
  `)};
`;
export const AccountInfo = Styled.div`
    ${tablet(css`
      display: none;
    `)};
`;
export const MobileList = Styled.div`
  display: none;
  ${tablet(css`
    display: block;
  `)};
`;
