import React from 'react';
import { CustomAnchor, Wrapper, Section } from './style';

export const Footer = () => (
  <Wrapper>
    <Section>
      <CustomAnchor href="https://uniwhales.io/" target="_blank">
        Copyright UniWhales ©2022
      </CustomAnchor>
    </Section>
    {/* <Section> */}
    {/*  <CustomAnchor href="#" target="_blank"> */}
    {/*    Artist Name and Details */}
    {/*  </CustomAnchor> */}
    {/*  <CustomAnchor href="https://passage.xyz/" target="_blank"> */}
    {/*    Powered by Passage Protocol */}
    {/*  </CustomAnchor> */}
    {/* </Section> */}
  </Wrapper>
);
