import React, { useState } from 'react';
import artwork from 'assets/svg/artwork.svg';
import subtract from 'assets/svg/subtract.svg';
import rectangle from 'assets/svg/rectangle.svg';
import faqs from 'assets/svg/faqs.svg';
import about from 'assets/svg/about.svg';
import folder from 'assets/svg/folder.svg';
import close from 'assets/svg/close.svg';
import { LoadingIndicator } from 'react95';
import ethereum from 'assets/svg/ethereum-eth.svg';
import errorIcon from 'assets/svg/error.svg';
import {
  Bar,
  CustomButton,
  CustomWindow,
  CustomWindowHeader,
  Img,
  TextElement,
  CustomWindowContent,
  TextWrapper,
  Wrapper,
  Header,
  NavigationButton,
  BarButton,
  CustomWrapper,
  MainDesktop,
  MainMobile,
  MobileNavigation,
  TextElementTitle,
  TextElementSubtitle, FAQSWrapper, AboutWrapper, EthereumWrapper, LoadingWrapper,
} from './style';
import { text } from '../../utils/data';
import { CustomAnchor } from '../footer/style';

interface ContentProps {
  isLoading: boolean;
  mint: () => void;
  error:unknown
  transactionHash: string | null;
  nftID: string | null
  balance: number;
  account: string | null;
}
export const Content = ({
  isLoading, mint, error, transactionHash, nftID, balance, account,
}:ContentProps) => {
  const { REACT_APP__NFT_LINK } = process.env;
  const [activeTab, setActiveTab] = useState<number | null>(null);
  const [fullWith, setFullWith] = useState<boolean>(false);
  const [hideToBottom, setHideToBottom] = useState<boolean>(false);
  const content = [
    { id: 0, name: 'About', img: about },
    { id: 1, name: 'Claim ', img: folder },
    { id: 2, name: 'FAQs', img: faqs },
  ];
  const getText = (id:number) => {
    if (id === 0) return text.about;
    if (id === 1) return text.claim;
    if (id === 2) return text.faqs;
    return text.undefined;
  };
  const handleBarAction = (id:number) => {
    if (id === 0) setHideToBottom(!hideToBottom);
    if (id === 1) {
      setFullWith(!fullWith);
      setHideToBottom(false);
    }
    if (id === 2) {
      setActiveTab(null);
      setFullWith(false);
    }
  };
  const headerActions = [
    { id: 0, img: rectangle, name: 'rectangle' },
    { id: 1, img: subtract, name: 'subtract' },
    { id: 2, img: close, name: 'close' },
  ];
  const faqsContent = (
    <div>
      {text.faqs.content.map((item) => (
        <FAQSWrapper key={item.id}>
          <TextElement textDecoration="underline" size="16">{item.q}</TextElement>
          <TextElement size="14">{item.a}</TextElement>
          <ol>
            {item.list.map((li) => (
              <li key={li.id}>{li.text}</li>
            ))}
          </ol>
          <TextElement>{item.note}</TextElement>
        </FAQSWrapper>
      ))}
    </div>
  );
  const aboutContent = (
    <div>
      {text.about.content.map((item) => (
        <AboutWrapper key={item.id}>
          <TextElement textDecoration="underline" size="16">{item.q}</TextElement>
          <TextElement size="14">{item.a}</TextElement>
          <TextElement>{item.note}</TextElement>
          {item.link && <CustomAnchor target="_blank" href="https://medium.com/@uniwhalesio/introducing-the-uniwhalooor-loyalty-nft-75b03d6229b8">https://medium.com/@uniwhale....</CustomAnchor>}
        </AboutWrapper>
      ))}
    </div>
  );
  const dynamicContent = () => {
    if (isLoading) {
      return (
        <LoadingWrapper>
          <TextElement>Loading...</TextElement>
          <LoadingIndicator isLoading />
        </LoadingWrapper>
      );
    }
    if (error) {
      return (
        <>
          <Img src={errorIcon} />
          <TextElement color="tomato">Transaction failed</TextElement>
        </>
      );
    }
    return (
      <Img maxWidth={fullWith ? '100%' : '220px'} src={nftID ? `${REACT_APP__NFT_LINK}${nftID}` : artwork} alt="artwork" />
    );
  };
  const claimContent = (
    <>
      <TextWrapper>
        {nftID ? (
          <>
            <TextElement>Congratulations!!!</TextElement>
            <TextElement>You successfully minted the nft</TextElement>
          </>
        ) : (
          <>
            <TextElement>Connect your wallet on Ethereum Mainnet</TextElement>
            <EthereumWrapper>
              <Img maxWidth="30px" src={ethereum} alt="ethereum" />
              <TextElement fontWeight="bold">Ethereum</TextElement>
            </EthereumWrapper>
            <TextElement>
              and see which
              <br />
              UniWhalooor you are!
            </TextElement>
          </>
        )}
      </TextWrapper>
      <CustomWrapper height={fullWith ? 'auto' : '226.5px'}>
        {transactionHash && (
        <CustomAnchor href={`https://etherscan.io/tx/${transactionHash}`} target="_blank">
          Transaction Hash
        </CustomAnchor>
        )}
        {dynamicContent()}
      </CustomWrapper>
      <CustomWrapper>
        {account && balance < 5000000000000000000000
        && <TextElement color="tomato">At least 5,000 UWL tokens require</TextElement>}
        <CustomButton
          disabled={isLoading || nftID || balance < 5000000000000000000000}
          onClick={() => mint()}
        >
          Claim NFT
        </CustomButton>
      </CustomWrapper>
    </>
  );
  const getContent = (id:number | null) => {
    if (id === 0) return aboutContent;
    if (id === 1) return claimContent;
    if (id === 2) return faqsContent;
    return <>hello there</>;
  };
  const box = (
    <CustomWindow key={10000} fullWith={fullWith} hideToBottom={hideToBottom}>
      <CustomWindowHeader>
        <TextElement size="14">
          {getText(activeTab as number).name}
        </TextElement>
        <Bar>
          {headerActions.map((item) => (
            <BarButton
              onClick={() => handleBarAction(item.id)}
              key={item.id}
            >
              <Img
                src={item.img}
                alt={item.name}
              />
            </BarButton>
          ))}
        </Bar>
      </CustomWindowHeader>
      <CustomWindowContent fullWith={fullWith} activeTab={activeTab} hideToBottom={hideToBottom}>
        {getContent(activeTab)}
      </CustomWindowContent>
    </CustomWindow>
  );

  return (
    <Wrapper>
      <Header fullWith={fullWith} activeTab={activeTab}>
        <TextElementTitle size="34">
          Claim your UniWhalooor NFT
        </TextElementTitle>
        <TextElementSubtitle size="26">
          Thanks for being a member of the UniWhales DAO.
          This loyalty NFT will complement your $UWL membership!
        </TextElementSubtitle>
      </Header>
      <MainDesktop>
        {content.map((item) => (
          item.id === activeTab ? box : (
            <NavigationButton
              fullWith={fullWith}
              onClick={() => setActiveTab(item.id)}
              key={item.id}
            >
              <Img src={item.img} alt={item.name} />
              <TextElement>{item.name}</TextElement>
            </NavigationButton>
          )
        ))}
      </MainDesktop>
      <MainMobile>
        <MobileNavigation>
          {content.map((item) => ((
            <NavigationButton
              fullWith={fullWith}
              active={activeTab === item.id}
              onClick={() => setActiveTab(item.id)}
              key={item.id}
            >
              <Img src={item.img} alt={item.name} />
              <TextElement>{item.name}</TextElement>
            </NavigationButton>
          )
          ))}
        </MobileNavigation>
        {activeTab !== null && box}
      </MainMobile>
    </Wrapper>
  );
};
