import logo from 'assets/svg/logo.svg';
import React, { useRef, useState } from 'react';
import { ListItem, Divider } from 'react95';
import { useClickOutside } from 'hooks/useClickOutside';
import {
  CustomButton,
  HeaderSection,
  Wrapper,
  Img,
  HeaderList,
  NetworkSwitch,
  AccountInfo,
  MobileList,
} from './style';

interface HeaderProps {
  network:string | null;
  account:string | null;
  disconnectWallet: () => void;
  connectWallet: () => void;
  isChainWrong: boolean;
  switchNetwork: () => void;
}
const ListForStart = [
  {
    id: 0, text: 'Home Page', img: '', link: 'https://uniwhales.io',
  },
  {
    id: 1, text: 'Twitter', img: '👨‍💻', link: 'https://twitter.com/uniwhalesio',
  },
  {
    id: 2, text: 'EVM Wallet Tracker', img: '🔙', link: 'https://t.me/EVMTrackerBot',
  },
  {
    id: 3, text: 'Near Wallet Tracker', img: '🔙', link: 'https://t.me/NearTrackerBot',
  },
];
export const Header = ({
  network, account, disconnectWallet, connectWallet, isChainWrong, switchNetwork,
}:HeaderProps) => {
  const clickRef = useRef(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  useClickOutside(clickRef, () => setOpen(false));
  return (
    <Wrapper>
      <HeaderSection ref={clickRef}>
        <CustomButton fullWidth onClick={() => setOpen(!isOpen)}>
          <Img
            src={logo}
            alt="uniwhales logo"
          />
          UniWhales
        </CustomButton>
        {isOpen && (
        <HeaderList
          onClick={() => setOpen(false)}
        >
          {ListForStart.map((li) => (
            <a key={li.id} target="_blank" href={li.link} rel="noreferrer">
              <ListItem key={li.id}>
                {li.text}
              </ListItem>
            </a>
          ))}
          <MobileList>
            {account && network && <Divider />}
            {account && <ListItem>{account}</ListItem>}
            {network && <ListItem>{network}</ListItem>}
          </MobileList>
        </HeaderList>
        )}
      </HeaderSection>
      <HeaderSection>
        <NetworkSwitch onClick={() => switchNetwork()} isChainWrong={isChainWrong}>
          { isChainWrong ? 'Wrong network' : network }
        </NetworkSwitch>
        <CustomButton
          onClick={() => {
            if (account !== 'No Account') disconnectWallet();
            else connectWallet();
          }}
        >
          {account !== 'No Account' ? 'Disconnect' : 'Connect'}
        </CustomButton>
        <AccountInfo>{account}</AccountInfo>
      </HeaderSection>
    </Wrapper>
  );
};
