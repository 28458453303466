import { useEffect, useState } from 'react';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import Web3 from 'web3';
import { AbstractProvider } from 'web3-core';
import { EventEmitter } from 'node:events';
import { AbiItem } from 'web3-utils';
import { chainIds, toHex } from '../utils';
import { CONTRACT_UWL, contractABI } from '../utils/data';

export interface RequestArguments {
  readonly method: string
  readonly params?: readonly unknown[] | object
}
export interface Provider extends EventEmitter {
  request(args: RequestArguments): Promise<unknown>
}

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: '064edfa7fed345449f7de145fe739b80',
    },
  },
};

const web3Modal = new Web3Modal({
  cacheProvider: true,
  providerOptions,
});

let provider:Provider | null;
let web3: Web3;

export const UseWeb3 = () => {
  const {
    REACT_APP__CONTRACT_ADDRESS, REACT_APP__CONTRACT_CHAIN_ID,
    REACT_APP__CONTRACT_UWL,
  } = process.env;
  const [account, setAccount] = useState<string | null>(null);
  const [error, setError] = useState<unknown | null>(null);
  const [chainId, setChainId] = useState<string | null | number>(null);
  const [balance, setBalance] = useState<number>(0);
  const [network, setNetwork] = useState(null);
  const [isChainWrong, setChainIsWrong] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [transactionHash, setTransactionHash] = useState<string | null>(null);
  const [nftID, setNftId] = useState<string | null>(null);
  const refreshState = () => {
    provider = null;
    setAccount(null);
    setError(null);
    setChainId(null);
    setNetwork(null);
    setNftId(null);
    setChainIsWrong(false);
    setNftId(null);
    setIsLoading(false);
  };
  const switchNetwork = async () => {
    try {
      await provider?.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: toHex(Number(REACT_APP__CONTRACT_CHAIN_ID)) }],
      });
    } catch (switchError:unknown) {
      setError(switchError);
    }
  };
  const getBalance = async (walletAddress:string) => {
    const contract = new web3.eth.Contract(CONTRACT_UWL as AbiItem[], REACT_APP__CONTRACT_UWL);
    contract.methods.balanceOf(walletAddress).call()
      .then((response:number) => {
        console.debug(response);
        setBalance(response);
      })
      .catch((err:unknown) => {
        console.error(err);
      });
  };
  const connectWallet = async () => {
    try {
      provider = await web3Modal.connect();
      web3 = new Web3(provider as unknown as AbstractProvider);
      const currentChainId = await web3.eth.getChainId();
      const currentAccounts = await web3.eth.getAccounts();
      if (currentAccounts) setAccount(currentAccounts[0]);
      setChainIsWrong(toHex(currentChainId) !== toHex(Number(REACT_APP__CONTRACT_CHAIN_ID)));
      setChainId(toHex(currentChainId));
      // @ts-ignore
      setNetwork(chainIds[currentChainId]);
      if (currentChainId.toString() !== REACT_APP__CONTRACT_CHAIN_ID) {
        switchNetwork()
          .then(() => console.debug('switchNetwork'));
      }
      await getBalance(currentAccounts[0]);
    } catch (err:unknown) {
      setError(err);
    }
  };
  const mint = async () => {
    if (account) {
      setError(null);
      setIsLoading(true);
      const contract = new web3.eth.Contract(contractABI as AbiItem[], REACT_APP__CONTRACT_ADDRESS);
      contract.methods.claim().send({ from: account })
        .once('sending', (payload: unknown) => console.debug('sending', payload))
        .once('sent', (payload: unknown) => console.debug('sent', payload))
        .once('transactionHash', (payload: string) => {
          console.log('transactionHash', payload);
          setTransactionHash(payload);
        })
        .once('receipt', (payload: unknown) => console.debug('receipt', payload))
        .once('confirmation', (payload: unknown) => console.debug('confirmation', payload))
        .once('error', (payload: unknown) => console.debug(payload))
        .then((response: unknown) => {
          // @ts-ignore
          setNftId(parseInt(response.events[0].raw.topics[3], 16));
        })
        .catch((err:unknown) => {
          setError(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      connectWallet().then(console.debug);
    }
  };
  const disconnectWallet = async () => {
    await web3Modal.clearCachedProvider();
    refreshState();
  };
  useEffect(() => {
    if (web3Modal.cachedProvider) {
      connectWallet().then(() => console.debug('web3Modal.cachedProvider'));
    }
  }, []);
  useEffect(() => {
    provider?.on('accountsChanged', (accounts:string[]) => {
      console.debug('accountsChanged', accounts);
      if (accounts) setAccount(accounts[0]);
    });
    provider?.on('chainChanged', (_hexChainId:number) => {
      setChainId(_hexChainId);
      setChainIsWrong(_hexChainId.toString() !== toHex(Number(REACT_APP__CONTRACT_CHAIN_ID)));
      // @ts-ignore
      setNetwork(chainIds[parseInt(_hexChainId, 16)]);
    });
    provider?.on('disconnect', () => {
      disconnectWallet().then(() => console.debug('disconnectWallet'));
    });
  }, [provider]);
  return {
    disconnectWallet,
    connectWallet,
    account,
    network,
    isChainWrong,
    error,
    chainId,
    switchNetwork,
    isLoading,
    mint,
    transactionHash,
    nftID,
    balance,
  };
};
