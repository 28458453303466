import Styled, { css } from 'styled-components';
import {
  Anchor,
} from 'react95';
import { BaseComponentWrapper } from '../../page/main/main.style';
import { phone, tablet } from '../../utils';

export const Wrapper = Styled(BaseComponentWrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
`;
export const Section = Styled.div`
  display: flex;
  gap: 10px;
`;
// custom window 95
export const CustomAnchor = Styled(Anchor)`
  color: rgba(102, 108, 129, 1);
  font-size: 14px;
  :visited {
    color : lightgrey;
  }
  ${tablet(css`
    font-size: 14px;
  `)};
  ${phone(css`
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100px;
    white-space: nowrap;
  `)};
`;
